/*--------head------------*/
.headercss {
    /*z-index: 10;*/
    background-color: #000;
}
.head {
    padding: 20px 0;
    color: #fff;
  }
  .logoImg {
      width: 50%;
  }
  .logo h1 {
    font-size: 35px;
    font-weight: 900;
  }
  .head .icon {
    margin-left: 10px;
  }
  /*--------head------------*/
  /*--------header------------*/
  header {
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 30px;
  }
  header .nav {
    display: flex !important;
  }
  header li {
    margin-right: 40px;
  }
  header ul {
    padding: 30px 20px;
  }
  header ul li a {
    color: #000;
    font-weight: 500;
    transition: 0.5s;
  }
  header ul li a:hover {
    color: #1eb2a6;
  }
  .start {
    background-color: #1eb2a6;
    padding: 30px 70px;
    color: #000;
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
  }
  .toggle {
    display: none;
  }
  /*--------header------------*/
  @media screen and (max-width: 768px) {
    .start {
      clip-path: none;
    }
    header {
      margin: 0;
      background-color: #1eb2a6;
      position: relative;
    }
    header ul.flexSB {
      display: none;
    }
    header ul li {
      margin-bottom: 20px;
    }
    .toggle {
      display: block;
      background: none;
      color: #000;
      font-size: 30px;
      position: absolute;
      right: 0;
      top: -20px;
    }
    .mobile-nav {
      position: absolute;
      top: 7vh;
      left: 0;
      width: 100%;
      background-color: #fff;
      z-index: 10;
    }
  }

/*------------global-----------*/
.container {
    max-width: 85%;
    margin: auto;
  }
  .flexSB {
    display: flex;
    justify-content: space-between;
  }
  .icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    color: #1eb2a6;
    transition: 0.5s;
  }
  .icon:hover {
    cursor: pointer;
    background-color: #1eb2a6;
    color: #fff;
  }
  a {
    text-decoration: none;
  }
  li {
    list-style-type: none;
  }
  .row {
    width: 50%;
  }
  button {
    padding: 17px 30px;
    background-color: #fff;
    color: #1eb2a6;
    font-weight: 600;
    border: none;
    margin: 30px 10px 0 0;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
    transition: 0.5s;
  }
  button i {
    margin-left: 20px;
  }
  button:hover {
    box-shadow: none;
  }
  .primary-btn {
    background-color: #1eb2a6;
    color: #fff;
  }

  /*------------global-----------*/
#heading {
    text-align: center;
    padding: 40px 0;
  }
  #heading h3 {
    font-weight: 600;
    letter-spacing: 1px;
    color: #1eb2a6;
    text-transform: uppercase;
  }
  #heading h1 {
    font-size: 45px;
    margin: 20px 0;
    text-transform: capitalize;
  }
  p {
    line-height: 30px;
    font-size: 18px;
  }
  
  .back {
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 30%;
    padding-right: 50px;
    color: #fff;
    text-align: center;
  }
  .back h1 {
    font-size: 100px;
    font-weight: 400;
  }
  .back h2 {
    font-weight: 500;
    font-size: 17px;
    text-transform: uppercase;
  }
  .margin {
    margin-top: 40.3%;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  .flex {
    display: flex;
  }
  .grid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  .outline-btn {
    margin: 0;
    box-shadow: none;
    border: 2px solid #1eb2a6;
    width: 100%;
    transition: 0.5s;
  }
  .outline-btn:hover {
    background-color: #1eb2a6;
    color: #fff;
  }
  .grid3 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
  }
  .padding {
    padding: 80px 0;
  }
  .shadow {
    box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
    background-color: #fff;
  }
  @media screen and (max-width: 768px) {
    .grid2,
    .grid3,
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .back {
      background-position: center;
      padding-top: 50%;
      height: 80vh;
    }
  }