.slider {
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    /*width: 1000px;*/
    width: 100%;
    max-height: 600px;
    border-radius: 10px;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: -32px;
    font-size: 3rem;
    color: #ffaf7f;
    /*color: #ff6633;*/
    z-index: 1;
    cursor: pointer;
    user-select: none;
  }

  .left-arrow {
    position: absolute;
    top: 50%;
    left: -32px;
    font-size: 3rem;
    color: #ffaf7f;
    /*color: #ff6633;*/
    z-index: 1;
    cursor: pointer;
    user-select: none;
  }
  
  
  @media screen and (max-width: 768px) {
      .right-arrow {
        right: 32px;
      }

      .left-arrow {
        left: 32px;
      }

      .slider {
        height: 50vh;
      }

  }

  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }

  .head-text {
    position: relative;
 }

 .text-on-image {
   position: absolute;
   right: 25%;
   left: 15%;
   bottom: 5%;
   color: white;
 }

 .little-description {
   color: #6E5E4E;
   font-size: 18px;
   padding-top: 10px;
   position: absolute;
 }

 .link-to-pablo {
  color:#ff6633;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
}