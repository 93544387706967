.i {
    display: flex;
    height: 210px;
    
  }
  
  .i-left {
    flex: 1;
    display: flex;
    /*align-items: center;
    justify-content: center;*/
  }
  
  .i-right {
    flex: 1;
    position: relative;
    height: 80%;
  }

  .i-desc{
    font-size: 18px;
   
  }
  
  .i-left-wrapper {
    /*padding: 20px;*/
    /*padding: 10px;*/
    /*height: 50%;*/
    height: 80%;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
  }
  
  .i-intro {
    color: #6E5E4E;
    font-size: 30px;
    font-weight: 300;
  }
  
  .i-name {
    color: #6E5E4E;
    font-size: 50px;
  }
  
  .i-title {
    height: 50px;
    overflow: hidden;
  }
  
  .i-title-wrapper {
    height: 100%;
    padding-bottom: 20px;
    animation: move 10s ease-in-out infinite alternate;
  }

  .tijdlijnImg{
    width: 85%;
  }
  
  @keyframes move {
    25% {
      transform: translateY(-50px);
    }
    50% {
      transform: translateY(-100px);
    }
    75% {
      transform: translateY(-150px);
    }
    100% {
      transform: translateY(-200px);
    }
  }
  
  .i-title-item {
    height: 50px;
    font-size: 25px;
    font-weight: bold;
    color: #ffaf7f;
    display: flex;
    align-items: center;
  }
  
  .i-scroll {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
  }
  
  .i-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
  
  .i-bg {
    clip-path: polygon(100% 0%, 100% 51%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
    background-color: #ffaf7f;
    width: 100%;
    height: 100%;
    /*width: 100%;*/
    position: absolute;
    top: 0;
    right: 0;
  }

  .bottom-one {
    margin-bottom: 10px;
    display:inline-block;
  }
  
  @media screen and (max-width: 768px) {
    .i {
      /*max-width: 250px;*/
      /*flex-direction: column;*/
      /*height: 1300px;*/
      /*padding: 20px;*/
    }
  
    .i-left-wrapper {
      /*padding: 10px;*/
      /*align-items: center;
      justify-content: center;
      text-align: center;*/
      height: 100%;
    }
  
    .i-desc{
      /*display: none;*/
    }

    
  .bottom-one {
    margin: 10px 10px 10px 10px;
    display:inline-block;
  }
    
  }